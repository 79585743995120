.alert {
  animation: fadeout 4s;
}

@keyframes fadeout {
  0%,
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
