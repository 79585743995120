.alert {
  animation: 4s fadeout;
}

@keyframes fadeout {
  0%, 30% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
/*# sourceMappingURL=index.f8e837fc.css.map */
